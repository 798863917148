// @flow

import React, { Fragment } from 'react';

type Props = {
  photo: ?string,
};

const UserPhoto = ({ photo }: Props) => {
  return (
    <Fragment>
      {photo && !photo.includes("null") ? (
        <div
          className="header__navigation-profile"
          style={{ backgroundImage: `url(//${photo})` }}
          ta-id="header_profile"
        />
      ) : (
        <div
          className="header__navigation-profile header__navigation-profile--default"
          ta-id="header_profile"
        />
      )}
    </Fragment>
  );
};

export default UserPhoto;
